import Fade from 'react-reveal/Fade'

import bgVideo from '../assets/videos/BGVideo.mp4'

const Hero = () => {
    return (
   
        <div className='relative h-full'>
            
            <video className="hidden md:flex lg:w-screen object-fit brightness-50"src={bgVideo} type="video/mp4" autoPlay loop muted/>
            
            {/* <div className='hidden md:flex'>
                <Fade bottom>
                    <button className="absolute flex top-10 right-10 w-[8rem] h-[5rem] bg-[#ff6800] text-4xl justify-center items-center" >CONTACT</button>
                </Fade>
            </div> */}
            <div className="flex flex-col lg:absolute lg:flex-row w-full h-full top-0">
                <div className='flex w-full justify-center items-center'>
                    <Fade bottom>
                        <h1 className="text-orange text-6xl xl:text-[20rem] 2xl:text-[40rem]">α<span className="text-white text-6xl xl:text-[20rem] 2xl:text-[40rem]">-ant</span></h1>
                    </Fade>
                </div>
                <div className='flex w-full text-[1.5rem] xl:text-[2.5rem] items-center'>
                    <Fade bottom>
                        <h1 className='w-full md:w-1/2 flex-wrap'>Embark on a fast-paced side-scrolling space shooter! Navigate your spacecraft through visually captivating environments, facing diverse enemies and challenging bosses. Arm yourself with powerful weapons, from lasers to missiles, for an adrenaline-fueled journey through space. Every moment is a battle for survival in this dynamic and visually immersive gaming experience!</h1>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Hero