import Fade from 'react-reveal/Fade'


const Attachment = () => {
    return (
        <div className="w-full bg-black px-[2rem]">
            <Fade bottom>
                <h1 className="text-[5rem] text-center">Collect different ship attachments.</h1>
            </Fade>

            <Fade bottom>
            <div className="flex flex-col md:flex-row pt-[5rem] pb-[5rem]">
                    <div className="flex w-full items-center">
                        <img class="xl:hover:scale-125 duration-300" src="../../images/Attachment.png" alt="Attachment"></img>
                    </div>
                    <div className="flex flex-col w-full text-orange justify-center items-start">
                        <h5 class="mb-2 text-orange text-9xl">ATT-82</h5>
                        <p class="mb-3 text-white text-4xl lg:w-1/2 flex-wrap">Introducing the Omni-Burst Modulator—an advanced spaceship weapon attachment that unleashes a barrage of diverse firepower. This cutting-edge attachment seamlessly integrates laser beams, homing missiles, and ion blasts, allowing pilots to adapt instantly to any combat scenario. The Omni-Burst Modulator ensures versatility and maximum impact in the ever-evolving challenges of deep space warfare.</p>
                    </div>
            </div>
            </Fade>
        </div>
    )
}

export default Attachment