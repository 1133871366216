
import Fade from 'react-reveal/Fade'

import bgVideo from '../assets/videos/Latest.mp4'

const MainVideo = () => {
    return (
        <div className="flex flex-col w-full h-full items-center justify-center bg-black pt-[5rem] pb-[5rem]">
            <div>
                <Fade bottom>
                    <h1 className='xl:text-[5rem]'>Latest gameplay video.</h1>
                </Fade>
            </div>
            <Fade bottom>
                <video className="w-4/5 "src={bgVideo} type="video/mp4" poster="../../images/wasp66.jpg" controls/>
            </Fade>
        </div>
    )
}

export default MainVideo