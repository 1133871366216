import Fade from 'react-reveal/Fade'

const Cards = () => {
    return (
        <div className="w-full bg-black">
            <Fade bottom>
                <h1 className="text-4xl lg:text-[5rem] text-center px-[5rem]">Visit different planets within the a-ant solar system.</h1>
            </Fade>
        
            <Fade bottom>
                <div className="grid grid-row-3 xl:grid-cols-3 gap-10  content-center justify-center pt-[5rem] pb-[5rem] px-[2.5rem]">
                    <div class="rounded-none">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/wasp66.jpg" alt="" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-9xl">WASP-66</h5>
                            <p class="mb-3 text-4xl">Discover an alien realm akin to Mars, inhabited solely by droids. In this barren landscape, metallic beings silently perform their tasks, creating an intriguing and robotic world devoid of traditional terrain or vistas.</p>
                        </div>
                    </div>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/wasp67.jpg" alt="" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-9xl ">WASP-67</h5>
                            <p class="mb-3 text-4xl">Brace yourself for an infernal adventure on an alien planet engulfed in rivers of molten lava. Navigate treacherous landscapes as hostile mechanical enemies lurk in the fiery abyss, creating a relentless and perilous environment for intrepid explorers.</p>
                        </div>
                    </div>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/wasp68.jpg" alt="" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-9xl ">WASP-68</h5>
                            <p class="mb-3 text-4xl">Step onto an alien planet resembling Earth, yet harboring a sinister secret. Beneath familiar skies and landscapes, a hostile organic lifeform thrives, turning the seemingly idyllic environment into a perilous and unpredictable realm for any intrepid visitors.</p>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
        
    )
}

export default Cards