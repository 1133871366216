import logo from './logo.svg';
import Header from './components/Header';
import Hero from './components/Hero';
import Cards from './components/Cards';
import Footer from './components/Footer';
import MainVideo from './components/MainVideo';
import Attachment from './components/Attachment';
import Included from './components/Included';

function App() {
  return (
    <main className="text-white font-steelfish">
      {/* <Header /> */}

      <div className="w-full xl:max-w-[3840px] mx-auto">
        <Hero />
        <MainVideo />
        <Cards />
        <Attachment />
        <Included />
        <Footer />
      </div>

    </main>
  );
}

export default App;
