import emailjs from '@emailjs/browser'
import React from 'react';

const Footer = () => {

    function sendEmail(e){
        e.preventDefault();

    emailjs.sendForm('service_6v4ysk7', 'template_rlmdthm', e.target, '_-o7fZhxZN6CmryLJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
    }

    return (
        <div className="h-[4rem] xl:h-[10rem] w-full h-full bg-[#27343A]">
            <form onSubmit={sendEmail} className="">
                <div className="flex flex-col lg:grid lg:grid-cols-2">
                    <div className="flex items-center justify-center lg:items-end lg:justify-end text-[2.5rem] lg:text-[15rem]">
                        <h1>Say Hi</h1>
                    </div>
                    <div className="flex flex-col items-left justify-center w-full text-4xl">
                        <div className='p-2'>
                            <input type="text" className="flex w-full bg-black border xl:w-1/2" placeholder="Name" name="name" required/>
                        </div>
                        <div className='p-2'>
                            <input type="text" className="flex w-full bg-black border xl:w-1/2" placeholder="Email" name="email" required/>
                        </div>
                        <div className='p-2'>
                            <textarea type="text" className="flex w-full bg-black border" placeholder="Your message" name="message" required/>
                        </div>
                        <div className="flex w-full p-2">
                            <input type="submit" className="bg-orange w-full py-2" value="Send Message" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Footer