import Fade from 'react-reveal/Fade'

const Included = () => {
    return (
        <div className="w-full bg-black">
            <Fade bottom>
                <h1 className="text-[5rem] text-center">Included with the download.</h1>
            </Fade>
        
            <div className="grid grid-row-3 xl:grid-cols-3 gap-10  content-center justify-center pt-[5rem] pb-[5rem] px-[2.5rem]">
                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/Blueprints.jpg" alt="Blueprints" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Blueprints</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/ChaosDestruction.jpg" alt="Chaos" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Chaos Destruction</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/ControlRig.jpg" alt="ControlRig" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">ControlRig</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/Level.jpg" alt="Level" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Level Setup</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/MetaSounds.jpg" alt="MetaSounds" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">MetaSounds</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/Niagara.jpg" alt="Niagara" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Niagara Particles</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/Sequencer.jpg" alt="Sequencer" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Sequencer</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/UI.jpg" alt="UI" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">UI</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

                <Fade bottom>
                    <div class="">
                        <img class="xl:hover:scale-110 duration-300" src="../../images/BlenderRig.jpg" alt="Blender" />
                        <div class="p-5">
                            <h5 class="mb-2 text-orange text-4xl lg:text-9xl">Blender Rig</h5>
                            <p class="mb-3 text-4xl"></p>
                        </div>
                    </div>
                </Fade>

            </div>
        </div>
        
    )
}

export default Included